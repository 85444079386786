import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  baseUrl = environment.url + '/api/v1'
  private apiKey = 'f7b6553b5f31203911779952e3310c76'; // replace with your API key
  private model = 'eleven_monolingual_v1';
  constructor(
    private http: HttpClient
  ) { }
  getToken(): Observable<{ token: string }> {
    const url = `${this.baseUrl}/audio`;
    return this.http
      .get<{ token: string }>(url)
      .pipe(catchError(this.handleError))
  }
  getStream(text: string, voiceId:string): Observable<any> {
    const url = `/v1/text-to-speech/${voiceId}/stream`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'xi-api-key': this.apiKey,
        'access-control-allow-origin':'*'
      }),
      responseType: 'text' as 'json'
    };
    return this.http
      .post(url, {
        model_id: this.model,
        text,
        voice_settings: {
          "stability": 0.35,
          "similarity_boost": 0.86,
          "style": 0,
          "use_speaker_boost": true,
          "optimize_streaming_latency": 4
        },
        xi_api_key: this.apiKey
      }, httpOptions)
      .pipe(catchError(this.handleError))
  }
  getResponse(id:string, text: string, timeLeft: number, token: string, soundBite?:Blob|null): Observable<any> {
    const url = `${this.baseUrl}/ask-avatar/${id}/stream`;
    //console.log('request', url, {query: text, timeRemaining: timeLeft|| 600000});
    //return of({});
    return this.http
      .post(url, { query: text, timeRemaining: timeLeft || 600000, token, soundBite }, {
        observe: "events",
        responseType: "text",
        reportProgress: true
      })
      .pipe(catchError(this.handleError))
  }
  getAudioFromText(chunks:any, voiceId: string): Observable<ArrayBuffer> {
    const url = `${this.baseUrl}/audio/text-to-voice`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'xi-api-key': this.apiKey,
        'access-control-allow-origin': '*'
      }),
      responseType: 'arraybuffer' as 'json'
    };
    return this.http
      .post<ArrayBuffer>(url, { chunks, voiceId },httpOptions )
      .pipe(catchError(this.handleError))
  }
  checkInterest(profile: any): Observable<any> {
    const url = `${this.baseUrl}/profile/new`;
    return this.http
      .post<any>(url, profile)
      .pipe(catchError(this.handleError))
  }

  private handleError(error: any): Observable<any> {
    console.log(error)
    return throwError(error.error || 'Server error');
  }
}

