<div class="app">
  <div class="one-trist">
    <!-- OneTrust Cookies Settings button start -->
    <button id="ot-sdk-btn" class="ot-sdk-show-settings"> Cookie Settings </button>
    <!-- OneTrust Cookies Settings button end -->

  </div>
  <!-- OneTrust Cookies List end -->
  <div id="analytics-tracker"></div>
  <router-outlet></router-outlet>
</div>