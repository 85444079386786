import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { ConvertTo12HrFormatPipe } from './pipes/convert24hr.pipe';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './pages/login/login.module';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { AuthInterceptor } from './providers/auth.interceptor';
import { PlaygroundModule } from './pages/playground/playground.module';
import { UserService } from './services/user.service';
import { SocketService } from './services/socket.service';
import { PromptService } from './services/prompt.service';
import { QueryService } from './services/query.service';
import { ChatService } from './services/chat.service';
import { ToastrModule } from 'ngx-toastr';
import { HomeModule } from './pages/home/home.module';
import { CookieService } from 'ngx-cookie-service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NewPlaygroundModule } from './pages/new-playground/new-playground.component.module';
import { SharedModule } from './shared/shared.module';
import { NgxAudioPlayerModule } from '@khajegan/ngx-audio-player';
import { VideoService } from './services/video.service';
import { RedirectModule } from './pages/redirect/redirect.module';
import { ResetModule } from './pages/reset-url/reset-url.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FontAwesomeModule,
    NgxAudioPlayerModule,
    NgScrollbarModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    RedirectModule,
    HomeModule,
    LoginModule,
    NewPlaygroundModule,
    PlaygroundModule,
    ResetModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [
    provideHttpClient(withFetch()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService,
    UserService,
    VideoService,
    ChatService,
    SocketService,
    PromptService,
    QueryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
