import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
let devRoutes: Routes = [];
const applicationRoutes: Routes = [
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/sign-up/sign-up.module').then((m) => m.SignupModule)
  },
  {
    path: 'reset-password',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/new-password/new-password.module').then((m) => m.NewPasswordModule)
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./pages/sign-up/sign-up.module').then((m) => m.SignupModule)
  },
  {
    path: 'forgot',
    loadChildren: () =>
      import('./pages/forgot/forgot.module').then((m) => m.ForgotModule)
  },
  {
    path: 'playground',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/playground/playground.module').then((m) => m.PlaygroundModule)
  },

  {
    path: 'own-goal',
    loadChildren: () =>
      import('./pages/own-goal/own-goal.module').then((m) => m.OwnGoalModule)
  },
  {
    path: 'terms',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/terms/terms.module').then((m) => m.TermsModule)
  },
  //TokenRoutingModule
  {
    path: 'token',
    loadChildren: () =>
      import('./pages/token/token.module').then((m) => m.TokenModule)
  },
  {
    path: 'unavailable',
    loadChildren: () =>
      import('./pages/unavailable/unavailable.module').then((m) => m.UnavailableModule)
  },
  //DisconnectedModule
  {
    path: 'disconnected',
    loadChildren: () =>
      import('./pages/disconnected/disconnected.module').then((m) => m.DisconnectedModule)
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule)
  },
  {
    path: 'reset-cookie',
    loadChildren: () =>
      import('./pages/reset-url/reset-url.module').then((m) => m.ResetModule)
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/production-ready/production-ready.module').then((m) => m.ProductionModule)
  },
  {
    path: 'text',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: '/', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '**', loadChildren: () =>
      import('./pages/redirect/redirect.module').then((m) => m.RedirectModule)
  },
];

if (environment.level !== 'production') {
  devRoutes = [
    {
      path: 'login',
      loadChildren: () =>
        import('./pages/login/login.module').then((m) => m.LoginModule)
    },
    {
      path: 'play',
      canActivate: [AuthGuard],
      loadChildren: () =>
        import('./pages/new-playground/new-playground.component.module').then((m) => m.NewPlaygroundModule)
    },
  ];

}
const routes: Routes = [...devRoutes, ...applicationRoutes];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule { }
