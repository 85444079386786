import { bootstrapApplication } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'; // Import the missing module
import { AppComponent } from './app/app.component';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => {
  console.log(err);
  throw new Error(err);
});

