import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private baseUrl = environment.apiUrl;
  constructor(
    public authService: UserService,
    private router: Router,
    private http: HttpClient
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.getUserValue()) {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: `Bearer ${this.authService.getUserValue()}`
        }
      });
    } else {
      req = req.clone({
        withCredentials: true
      });
    }
    return next.handle(req).pipe(tap(() => { return },
      (err: any) => {
        const locationPath = window.location.pathname
        if (err instanceof HttpErrorResponse) {
          if (!locationPath.includes('/landing')) {
            if (err.status !== 498) {
              return;
            }
            this.router.navigate(['/landing']);
          }
        }
      }));
  }

  refreshToken(token: string): Observable<any> {

    const url = `${this.baseUrl}/auth/refreshtoken`;

    return this.http.post(url, {
      refreshToken: token
    });

  }

}
